import React from 'react'
import ClientAccountsCard from '../components/clientAccounts/ClientAccountsCard';

export default function ClientAccounts() {


    return (
        <div>
            <ClientAccountsCard />
        </div>
    )

}

