import React from 'react'
import BusinessCard from '../components/business/BusinessCard'

export default function BusinessRelationship() {

    return (

        <div>
            <BusinessCard />
        </div>

    )
}